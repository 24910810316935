import React, { useState, useEffect } from "react";
// @ts-ignore
import * as d3 from "d3";
import data from "../datacopy.json";

const SIZE = 975;
const RADIUS = SIZE / 2;

const SunburstChart = () => {
  const svgRef = React.useRef<SVGSVGElement>(null);

  const partition = (data: any) =>
    d3.partition().size([2 * Math.PI, RADIUS])(
      d3
        .hierarchy(data)
        .sum((d: any) => d.value)
        .sort((a: any, b: any) => b.value - a.value)
    );

  const color = d3.scaleOrdinal(
    d3.quantize(d3.interpolateRainbow, data.children.length + 1)
  );

  const format = d3.format(",d");

  const arc: any = d3
    .arc()
    .startAngle((d: any) => d.x0)
    .endAngle((d: any) => d.x1)
    .padAngle((d: any) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(RADIUS / 2)
    .innerRadius((d: any) => d.y0)
    .outerRadius((d: any) => d.y1 - 1);

  const getAutoBox = () => {
    if (!svgRef.current) {
      return "";
    }
    const { x, y, width, height } = svgRef.current.getBBox();
    return [x, y, width, height].toString();
  };

  React.useEffect(() => {
    const root = partition(data);

    const svg = d3.select(svgRef.current);

    const makeStressedHighlight = () => {
      const titleTags = document.getElementsByTagName("title");
      const searchText = "emotions/Bad/Stressed\n24";
      let found: any;

      for (let i = 0; i < titleTags.length; i++) {
        if (titleTags[i].textContent === searchText) {
          found = titleTags[i];
          break;
        }
      }
      found.parentElement.classList.add("stressCell");
      found.parentElement.innerHTML = "Dom Rerender";
    };

    const tooldiv = d3
      .select("#chartArea")
      .append("div")
      .style("visibility", "hidden")
      // .style("position", "absolute")
      .style("background-color", "white")
      .style("padding", "0.5em 1.5em")
      .style("min-width", "250px")
      .style("max-width", "500px")
      .style("border", "1px solid #e8e8e8")
      .style("font-family", "Helvetica")
      .style("position", "fixed");

    const mobileVersion = () =>
      tooldiv
        .style("top", "0%")
        .style("left", "0%")
        .style("width", "100%")
        .style("height", "100%")
        .style("max-height", "100%")
        .style("overflow-y", "scroll")
        .style("overflow-x", "hidden")
        .style("box-sizing", "border-box");

    const desktopVersion = () =>
      tooldiv
        .style("top", "3%")
        .style("left", "77%")
        .style("max-height", "85%")
        .style("overflow-y", "scroll");

    window.innerWidth > 600 ? desktopVersion() : mobileVersion();

    window.addEventListener("resize", function () {
      if (window.innerWidth > 500) {
        desktopVersion();
      } else {
        mobileVersion();
      }
    });

    svg
      .append("g")
      .attr("fill-opacity", 0.9)
      .selectAll("path")
      .data(root.descendants().filter((d: any) => d.depth))
      .join("path")
      .attr("fill", (d: any) => {
        while (d.depth > 1) d = d.parent;
        return color(d.data.name);
      })
      .attr("d", arc)
      .on("click", (event: MouseEvent, d: any) => {
        tooldiv.style("visibility", "visible").html(`
            <div style="display: flex; justify-content: space-between; align-items: flex-start;">
              <h3><strong>${d.data.name}</strong></h3>
              <button id="closeModal" style="margin-left: auto; margin-top: 1rem">Close</button>
            </div>
            <p>${d.data.subDescription}</p>
            <hr/>
            <h4>Why do we feel ${d.data.name}?</h4>
            <p>${d.data.description}</p>
            <hr/>
            <h4>When do we feel ${d.data.name}?</h4>
            <p>${d.data.whenDescription}</p>
            <p>e.g ${d.data.whenExample}</p>
            <hr/>
            <h4>What to do when we feel ${d.data.name}?</h4>
            <p>${d.data.processing}</p>
            <button id="closeModal2" style="margin-right: auto;">Close</button>
          `);
        // Added close buttons

        d3.select("#closeModal").on("click", (event: MouseEvent) => {
          tooldiv.style("visibility", "hidden");
          event.stopPropagation();
        });
        d3.select("#closeModal2").on("click", (event: MouseEvent) => {
          tooldiv.style("visibility", "hidden");
          event.stopPropagation();
        });
      })

      .append("title")
      .text(
        (d: any) =>
          `${d
            .ancestors()
            .map((d: any) => d.data.name)
            .reverse()
            .join("/")}\n${format(d.value)}`
      );

    svg
      .append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .attr("font-size", 10)
      .attr("font-family", "sans-serif")
      .selectAll("text")
      .data(
        root
          .descendants()
          .filter(
            (d: any) => d.depth && ((d.y0 + d.y1) / 2) * (d.x1 - d.x0) > 10
          )
      )
      .join("text")
      .attr("transform", function (d: any) {
        const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
        const y = (d.y0 + d.y1) / 2;
        return `rotate(${
          x - 90
        }) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
      })
      .attr("dy", "0.35em")
      .text((d: any) => d.data.name);

    svg.attr("viewBox", getAutoBox);
    makeStressedHighlight();
  }, [arc, color, format]);

  // Get the current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width to state
      setWindowWidth(window.innerWidth);
    }

    // Subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResize);

    return () => {
      // Unsubscribe from window resize event "onComponentWillUnmount"
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 980;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "start",
        padding: "5% 2%",
      }}
    >
      <div
        style={{
          maxWidth: "100%",
          marginLeft: "2%",
          marginBottom: isMobile ? "2em" : "0",
        }}
      >
        <h2>Feelings Compass</h2>
        <p>AI Powered Feelings Wheel using Chat GPT 3.5 and 4.</p>
        <h3>How to use</h3>
        <p>Zoom in and click on the area of the wheel you want to inspect.</p>
        <p>
          If you are using on mobile you will have to zoom out to read the
          popup.
        </p>
        <p>
          Click on a feeling that feels the most relatable to your current
          situation.
        </p>
        <p>
          Remember, choosing a single emotion is better than choosing the
          perfect one.
        </p>
      </div>
      <div
        id="chartArea"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <svg
          width={isMobile ? "100%" : SIZE}
          height={isMobile ? "100%" : SIZE}
          ref={svgRef}
        />
        <div style={{ textAlign: "center", marginTop: "4rem" }}>
          The feelings compass is an
          <a
            href="https://www.linkedin.com/company/equilibrium-technology/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: "5px" }}
          >
            Equilibrium Technology Product
          </a>
        </div>
      </div>
    </div>
  );
};

export default SunburstChart;
